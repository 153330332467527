<template>
    <div id="root">
        <div :class="`${cssClasses} full-width`">
            <component
                :is="$route.meta.layout"
                :is-x-small="screen.getScreenSizeInfo.isXSmall"
                :is-large="screen.getScreenSizeInfo.isLarge"
            >
                <router-view></router-view>
            </component>
            <!--=================================Thông báo==============================-->
            <PopupsVue
                :Params="{
                    ShowButtonLeft: false,
                    ShowButtonRight: false,
                    ShowButtonClose: false,
                }"
                Width="350"
                Height="auto"
                :Model="dialogDangPhatTrien"
            >
                <template #popups>
                    <div class="column justify-center align-center">
                        <div class="font-24">Chức năng đang phát triển</div>
                        <DxButton
                            id="btn-dangphattrien"
                            class="mt-3"
                            text="Đồng ý"
                            type="default"
                            styling-mode="contained"
                            @click="
                                $store.commit(
                                    'trangChu/setDialogDangPhatTrien',
                                    false
                                )
                            "
                        />
                    </div>
                </template>
            </PopupsVue>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import {
    getCurrentInstance,
    reactive,
    onMounted,
    onBeforeUnmount,
    computed,
} from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import PopupsVue from "@sonphat/common-v1/components/Popups";
function getScreenSizeInfo() {
    const screenSizes = sizes();

    return {
        isXSmall: screenSizes["screen-x-small"],
        isLarge: screenSizes["screen-large"],
        cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
    };
}

export default {
    setup() {
        const vm = getCurrentInstance();

        const title = vm.proxy.$appInfo.title;
        const screen = reactive({ getScreenSizeInfo: {} });
        screen.getScreenSizeInfo = getScreenSizeInfo();

        function screenSizeChanged() {
            screen.getScreenSizeInfo = getScreenSizeInfo();
        }

        onMounted(() => {
            subscribe(screenSizeChanged);
        });

        onBeforeUnmount(() => {
            unsubscribe(screenSizeChanged);
        });

        const cssClasses = computed(() => {
            return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
        });

        return {
            title,
            screen,
            cssClasses,
        };
    },
    components: {
        PopupsVue,
        DxButton
    },
    computed: {
        ...mapGetters("trangChu", ["getCauHinhChung"]),
        ...mapState("trangChu", {
            dialogDangPhatTrien: (state) => state.dialogDangPhatTrien,
        }),
    },
    
    methods: {
        ...mapActions("idleTime", ["startIdleTimer"]),
    },
    async mounted() {
        this.startIdleTimer(this.getCauHinhChung.thoiGianQuayLaiTrangChu);
        let height = document.querySelector("html").clientHeight;
        let width = document.querySelector("html").clientWidth;
        let temp = width / height
        if(temp < 1){
            
            this.$store.dispatch("ManHinh/manHinhAction", "doc")
        }else {
            document.querySelector("html").style.maxWidth = '100%'
            this.$store.dispatch("ManHinh/manHinhAction", "ngang")
        }
        
    },
};
</script>

<style lang="scss">
html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

// * {
//     box-sizing: border-box;
// }

.app {
    @import "./themes/generated/variables.base.scss";
    background-color: #fff;
    display: flex;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}
</style>
